import React, { useState, useEffect, useRef } from 'react';

const ShapeSelectorModal = ({ showShapeModal, setShowShapeModal, handleShapeSelection }) => {
  const [selectedShape, setSelectedShape] = useState(null);
  const modalRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowShapeModal(false);
        setSelectedShape(null);
      }
    };

    if (showShapeModal) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showShapeModal]);

  const handleShapeClick = (shape) => {
    setSelectedShape(shape);
    handleShapeSelection(shape);
  };


  const closeModal = () => {
    setShowShapeModal(false);
    setSelectedShape(null);
  };

  return (
    showShapeModal && (
      <div className="modal shapesModal">
        <div className="modal-dialog shapes_wrap" ref={modalRef}>
          <div className="modal-content shape_content">
            {/* <span className="close" onClick={() => setShowShapeModal(false)}> */}
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>Select a Shape</h2>
            <div className="shapesButtonList">
              <ul>
                <li
                  title="circle"
                  className={selectedShape === 'circle' ? 'active' : ''}
                  onClick={() => handleShapeClick('circle')}
                >
                  <img src="https://animationish-dev-images.s3.amazonaws.com/advanced/circle.png" alt="Circle" />
                </li>
                <li
                  title="Square"
                  className={selectedShape === 'rectangle' ? 'active' : ''}
                  onClick={() => handleShapeClick('rectangle')}
                >
                  <img src="https://animationish-dev-images.s3.amazonaws.com/advanced/rectangle.png" alt="Rectangle" />
                </li>
                <li
                  title="triangle"
                  className={selectedShape === 'triangle' ? 'active' : ''}
                  onClick={() => handleShapeClick('triangle')}
                >
                  <img src="https://animationish-dev-images.s3.amazonaws.com/advanced/triangle.png" alt="Triangle" />
                </li>
                <li
                  title="line"
                  className={selectedShape === 'line' ? 'active' : ''}
                  onClick={() => handleShapeClick('line')}
                >
                  <img src="https://animationish-dev-images.s3.amazonaws.com/advanced/line.png" alt="Line" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShapeSelectorModal;
