import React,{useState}  from "react";
import ShapeSelectorModal from "../../components/ShapeSelectorModal";

const LeftTools = props => {
 const [groupImg, setGroupImg]= useState(true);
  const [showShapeModal, setShowShapeModal] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  
  
  const handleShapeSelection = (shape) => {
    props.setSelectedTool(shape);
    setSelectedShape(shape);
    setShowShapeModal(false);
  };
  return (
    <div className="wiggle_doddle_main_mid_left col-sm-2 ">
      <div className="tool_bar">
        <img src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/tools-outline-long-vertical.svg" />
        <div className="overlay_classes">
          <ul>
            <li
              className={props.selectedTool === "select" ? "active_tools" : ""}
              onClick={() => {
                props.setSelectedTool("select");
              }}
              title="Select"
            >
              <img src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/pointer.png" />
            </li>
            <li
              className={props.selectedTool === "pencil" ? "active_tools" : ""}
              title="Brush"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/brush.png"
                onClick={() => {
                  props.setSelectedTool("pencil");
                }}
              />
            </li>
            <li
              className={
                props.selectedTool === "paintBucket" ? "active_tools" : ""
              }
              title="Paint"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/color.png"
                onClick={() => {
                  props.setSelectedTool("paintBucket");
                }}
              />
            </li>
            <li
              className={props.selectedTool === "eraser" ? "active_tools" : ""}
              title="Eraser"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/rubber.png"
                onClick={() => {
                  props.setSelectedTool("eraser");
                }}
              />
            </li>
            <li
              className={props.selectedTool === "groupSelection" || props.selectedTool === "ungroupSelection"
              ? "active_tools"
              : ""}
              onClick={(e) => {
                if (props.selectedTool === "groupSelection") {
                  // Handling for groupSelection
                  props.checkIfGroupAllEnabled(groupImg);
                  setGroupImg(!groupImg);
                } else if (props.selectedTool === "ungroupSelection") {
                  // Handling for ungroupSelection
                  // Additional handling for ungroupSelection if needed
                  setGroupImg(!groupImg);
                }
            
                // Toggle between groupSelection and ungroupSelection
                props.setSelectedTool(
                  props.selectedTool === "groupSelection" ? "ungroupSelection" : "groupSelection"
                );
              }}
             
              title={
                props.selectedTool === "groupSelection"
                  ? "groupSelection"
                  : "ungroupSelection"
              }
            >
               <img 
                src={
                  groupImg
                    ? props.selectedTool === "groupSelection"
                      ? "https://animationish-dev-images.s3.amazonaws.com/advanced/ungroup.png"
                      : "https://animationish-dev-images.s3.amazonaws.com/advanced/group.png"
                    : props.selectedTool === "ungroupSelection"
                    ? "https://animationish-dev-images.s3.amazonaws.com/advanced/group.png"
                    : "https://animationish-dev-images.s3.amazonaws.com/advanced/ungroup.png"
                }
               />

            </li>
            <li>
            <li
              id="flip_Shapes"
              title="Shapes"
              className={
                props.selectedTool === "circle" ||
                props.selectedTool === "rectangle" ||
                props.selectedTool === "line" ||
                props.selectedTool === "triangle"
                  ? "active_tools"
                  : ""
              }
              onClick={() => setShowShapeModal(true)}
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/advanced/shapes.png"
                onClick={() => {
                  // props.setSelectedTool("pan");
                }}
                alt="Shapes"
              />
            </li>
            </li>
           
          </ul>
        </div>
      </div>
      
      {/*<div className="inspire_me_image">
        <img
          className="normal_show"
          src="images/wiggle_doddle_main/inspireme_small.png"
        />
        <img
          style={{ display: "none" }}
          className="hover_show"
          src="images/wiggle_doddle_main/inspireme_small_over.gif"
        />
        <img
          style={{ display: "none" }}
          className="click_show"
          src="images/wiggle_doddle_main/inspireme_small_click.png"
        />
      </div>*/}
      <ShapeSelectorModal
        showShapeModal={showShapeModal}
        setShowShapeModal={setShowShapeModal}
        handleShapeSelection={handleShapeSelection}
        selectedShape={selectedShape}
      />

    </div>
  );
};

export default LeftTools;
