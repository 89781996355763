/*eslint no-unused-vars: 0*/

import FabricCanvasTool from "./fabrictool";
// const fabric = require("fabric").fabric;

class Select extends FabricCanvasTool {
	configureCanvas(props) {
		let canvas = this._canvas;
		canvas.isDrawingMode = false;
		canvas.selection = true;
		canvas.forEachObject(o => {
			o.selectable = o.evented = o.hasControls = o.hasBorders = true;
			// if (props.advancedSection) {
			// 	o.hasRotatingPoint = false;
			// }
		});
		canvas.rotationCursor = 'url("https://animationish-dev-images.s3.amazonaws.com/rotate.svg"), auto';


		// canvas.discardActiveObject();
        // var sel = new fabric.ActiveSelection(canvas.getObjects(), {
        //   canvas: canvas,
        // });
        // canvas.setActiveObject(sel);
        // canvas.requestRenderAll();
	}
}

export default Select;
