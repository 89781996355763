import React, { Component } from "react";

class ForgotPassword extends Component {
  loginUser() {
    this.props.history.push("/dashboard");
  }

  render() {
    return (
      <div className="body_wrap">
        <div className="conta_iner">
          <div className="animation_ish_box">
            <div className="log_in_page">
              <div className="login_logo">
                <div className="logo_img_sec">
                  <img src="https://animationish-dev-images.s3.amazonaws.com/login/logo.png" />
                </div>
              </div>
              <div className="login_fields">
                <div className="login_heading">
                  <h2>Forgot Password</h2>
                </div>
                <div className="all_login_fields">
                  <div className="input_fields">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                    />
                  </div>
                  <div className="input_fields submit_fields">
                    <input
                      type="submit"
                      value="Submit"
                      onClick={() => this.loginUser()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
