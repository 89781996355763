import React, { Component } from "react";
import $ from "jquery";

class ColorPickerModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pickerSelectedColor: ""
		};
		this.useCanvas = this.useCanvas.bind(this);
		this.rgbToHex = this.rgbToHex.bind(this);
		this.componentToHex = this.componentToHex.bind(this);
	}

	componentDidUpdate(prevProps) {
		let { selectedBrushColor } = this.props;
		if (prevProps.selectedBrushColor !== selectedBrushColor) {
			document.getElementById(
				"cloudImg"
			).style.backgroundColor = selectedBrushColor;
			this.setState({
				pickerSelectedColor: selectedBrushColor
			});
		}
	}

	componentDidMount() {
		let context = this;
		var img = document.getElementById("colorPickerImg"),
			canvas = document.getElementById("cs"),
			x = "",
			y = "";

		// Ensure the image is CORS-enabled
		img.crossOrigin = "anonymous";
		img.addEventListener("mousemove", function(e) {
			if (e.offsetX) {
				x = e.offsetX;
				y = e.offsetY;
			} else if (e.layerX) {
				x = e.layerX;
				y = e.layerY;
			}

			context.useCanvas(canvas, img, function() {
				var p = canvas.getContext("2d").getImageData(x, y, 1, 1).data;
			});
		});

		img.addEventListener(
			"click",
			function(e) {
				if (e.offsetX) {
					x = e.offsetX;
					y = e.offsetY;
				} else if (e.layerX) {
					x = e.layerX;
					y = e.layerY;
				}
				context.useCanvas(canvas, img, function() {
					var p = canvas.getContext("2d").getImageData(x, y, 1, 1)
						.data;
					context.setCloudColor(context.rgbToHex(p[0], p[1], p[2]));
				});
			},
			false
		);
	}

	setCloudColor(color) {
		document.getElementById("cloudImg").style.backgroundColor = color;
		this.setState({
			pickerSelectedColor: color
		});
	}

	rgbToHex(r, g, b) {
		return (
			"#" +
			this.componentToHex(r) +
			this.componentToHex(g) +
			this.componentToHex(b)
		);
	}

	componentToHex(c) {
		var hex = c.toString(16);
		return hex.length == 1 ? "0" + hex : hex;
	}

	useCanvas(el, image, callback) {
		el.width = image.width;
		el.height = image.height;
		el.getContext("2d").drawImage(image, 0, 0, image.width, image.height);
		return callback();
	}

	closeColorPickerModal() {
		this.setState({
			pickerSelectedColor: ""
		});
		document.getElementById("cloudImg").style.backgroundColor = "";
		$("[data-dismiss=modal]").trigger({ type: "click" });
	}

	render() {
		let { pickerSelectedColor } = this.state;
		return (
			<div className="modale_animation">
				<button
					type="button"
					data-toggle="modal"
					id="openColorPickerModal"
					style={{ display: "none" }}
					data-target="#color_picker_modal"
				>
					Open Modal
				</button>

				<div
					className="modal fade"
					id="color_picker_modal"
					role="dialog"
				>
					<div className="modal-dialog">
						<div className="modal-header">
							<button
								type="button"
								className="close"
								data-dismiss="modal"
							>
								&times;
							</button>
						</div>
						<div className="modal-content">
							<div className="modal-body" id="modal-body">
								<div className="color_picker_popup">
									<div className="cloud_img">
										<img
											src="https://animationish-dev-images.s3.amazonaws.com/colud.png"
											id="cloudImg"
										/>
										<div className="inner_cloud_color" />
									</div>
									<div className="color_picker_image_div">
										<div className="coor_picker_img">
											<img
												src="images/color_pick.jpg"
												id="colorPickerImg"
												style={{ cursor: "crosshair" }}
											/>
										</div>
										<div className="coor_picker_text">
											<img src="https://animationish-dev-images.s3.amazonaws.com/color_choose.png" />
										</div>
									</div>
									<div className="color_picker_ok_cancle_button">
										<div
											className="ok_picker_button"
											onClick={() => {
												this.props.handleColorPicker(
													pickerSelectedColor
												);
												this.setState({
													pickerSelectedColor: ""
												});
											}}
										>
											<a href="javascript:void(0)">
												<img src="https://animationish-dev-images.s3.amazonaws.com/okay_picker_button.png" />
											</a>
										</div>
										<div className="ok_picker_button cancle_picker_button">
											<a
												href="javascript:void(0)"
												onClick={() => {
													this.closeColorPickerModal();
												}}
											>
												<img src="https://animationish-dev-images.s3.amazonaws.com/cancle_picker_button.png" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<canvas id="cs" />
			</div>
		);
	}
}

export default ColorPickerModal;
