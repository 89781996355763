import React, { useState } from "react";
import classnames from "classnames";
import ShapeSelectorModal from "../../components/ShapeSelectorModal";

const LeftTools = (props) => {
  const [groupImg, setGroupImg] = useState(true);
  const [showShapeModal, setShowShapeModal] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  const { selectedTool, setSelectedTool } = props;

  const handleShapeSelection = (shape) => {
    props.setSelectedTool(shape);
    setSelectedShape(shape);
    setShowShapeModal(false);
  };

  return (
    <div className="wiggle_doddle_main_mid_left col-sm-2 advanced_left_tool">
      <div className="tool_bar">
        <img src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/tools-outline-long-vertical.svg" />
        <div className="overlay_classes">
          <ul style={{ cursor: "default" }}>
            <li
              className={selectedTool === "select" ? "active_tools" : ""}
              onClick={() => {
                setSelectedTool("select");
              }}
              title="Select"
            >
              <img src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/pointer.png" />
            </li>
            <li
              className={props.selectedTool === "pencil" ? "active_tools" : ""}
              title="Brush"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/brush.png"
                onClick={() => {
                  props.setSelectedTool("pencil");
                }}
              />
            </li>
            <li
              className={
                props.selectedTool === "paintBucket" ? "active_tools" : ""
              }
              title="Paint"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/color.png"
                onClick={() => {
                  props.setSelectedTool("groupSelection", () => props.setSelectedTool("paintBucket"));
                  // props.setSelectedTool("paintBucket");
                }}
              />
            </li>
            <li
              className={props.selectedTool === "eraser" ? "active_tools" : ""}
              title="Eraser"
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/wiggle_doddle_main/rubber.png"
                onClick={() => {
                  props.setSelectedTool("eraser");
                }}
              />
            </li>
            <li
              className={
                props.selectedTool === "groupSelection" ||
                props.selectedTool === "ungroupSelection"
                  ? "active_tools"
                  : ""
              }
              onClick={(e) => {
                if (props.selectedTool === "groupSelection") {
                  // Handling for groupSelection
                  props.checkIfGroupAllEnabled(groupImg);
                  setGroupImg(!groupImg);
                } else if (props.selectedTool === "ungroupSelection") {
                  // Handling for ungroupSelection
                  // Additional handling for ungroupSelection if needed
                  setGroupImg(!groupImg);
                }

                // Toggle between groupSelection and ungroupSelection
                props.setSelectedTool(
                  props.selectedTool === "groupSelection"
                    ? "ungroupSelection"
                    : "groupSelection"
                );
              }}
              title={
                props.selectedTool === "groupSelection"
                  ? "groupSelection"
                  : "ungroupSelection"
              }
            >
              <img
                src={
                  groupImg
                    ? props.selectedTool === "groupSelection"
                      ? "https://animationish-dev-images.s3.amazonaws.com/advanced/ungroup.png"
                      : "https://animationish-dev-images.s3.amazonaws.com/advanced/group.png"
                    : props.selectedTool === "ungroupSelection"
                    ? "https://animationish-dev-images.s3.amazonaws.com/advanced/group.png"
                    : "https://animationish-dev-images.s3.amazonaws.com/advanced/ungroup.png"
                }
                alt="groupSelection"
              />
            </li>

            <li
              title="Shapes"
              className={
                props.selectedTool === "circle" ||
                props.selectedTool === "rectangle" ||
                props.selectedTool === "line" ||
                props.selectedTool === "triangle"
                  ? "active_tools"
                  : ""
              }
              onClick={() => setShowShapeModal(true)}
            >
              <img
                src="https://animationish-dev-images.s3.amazonaws.com/advanced/shapes.png"
                onClick={() => {
                  // props.setSelectedTool("pan");
                }}
                alt="Shapes"
              />
            </li>

            {/* <li
              title="circle"
              className={props.selectedTool === "circle" ? "active_tools" : ""}
            >
              <div
              onClick={() => {
                props.setSelectedTool("circle");
              }}
              
              >
              circle
              </div>
              <img
                // src="images/advanced/hand.png"
                
              />
            </li> */}

            {/* <li
              title="rectangle"
              className={props.selectedTool === "rectangle" ? "active_tools" : ""}
            >
              <div
              onClick={() => {
                props.setSelectedTool("rectangle");
              }}
              >
              rectangle
              </div>
              <img
                // src="images/advanced/hand.png"
                
              />
            </li> */}

            {/* <li
              title="triangle"
              className={props.selectedTool === "triangle" ? "active_tools" : ""}
            >
              <div
              onClick={() => {
                console.log('props',props)
                props.setSelectedTool("triangle");
              }}
              >
              triangle
              </div>
              <img
                // src="images/advanced/hand.png"
                
              />
            </li> */}

            {/* <li
              title="line"
              className={props.selectedTool === "line" ? "active_tools" : ""}
            >
              <div
              onClick={() => {
                props.setSelectedTool("line");
              }}
              >
              line
              </div>
              <img
                // src="images/advanced/hand.png"
                
              />
            </li> */}

            {/* <li
              title="Grabber"
              className={props.selectedTool === "pan" ? "active_tools" : ""}
            >
              <img
                src="images/advanced/hand.png"
                onClick={() => {
                  props.setSelectedTool("pan");
                }}
              />
            </li> */}
            {/* <li
              title="Transform"
              className={
                props.selectedTool === "transform" ? "active_tools" : ""
              }
            >
              <img
                src="images/advanced/transform.png"
                onClick={() => {
                  props.setSelectedTool("transform");
                }}
              />
            </li> */}
          </ul>
        </div>
        {/* <div className="color_box next_prev_play_button">
          <div className="play_pause_button">
            <div className="next_pre_button play_button_pause_sec">
              <ul>
                <li
                  onClick={() => playTransform()}
                  className="active_button"
                  title="Play"
                >
                  <img src="images/okay_picker_button.png" />
                </li>
                {!playing && (
                  <li
                    onClick={
                      !checkIfPlayButtonIsEnabled() || looping
                        ? () => {}
                        : () => playAnimation(-1, "play")
                    }
                    className={classnames({
                      active_button: checkIfPlayButtonIsEnabled() && !looping,
                    })}
                    title="Play"
                  >
                    <img src="images/flipbook/play.png" />
                  </li>
                )}
                {playing && (
                  <li
                    onClick={() => stopAnimation()}
                    className="active_button"
                    title="Stop"
                  >
                    <img src="images/flipbook/pause.png" />
                  </li>
                )}

                {!looping && (
                  <li
                    onClick={
                      !checkIfPlayButtonIsEnabled() || playing
                        ? () => {}
                        : () => playAnimation(0, "loop")
                    }
                    className={classnames({
                      active_button: checkIfPlayButtonIsEnabled() && !playing,
                    })}
                    title="Loop"
                  >
                    <img src="images/flipbook/loop.png" />
                  </li>
                )}

                {looping && (
                  <li
                    onClick={() => stopAnimation()}
                    className="active_button"
                    title="Stop"
                  >
                    <img src="images/flipbook/pause.png" />
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className="slow_fast_buttons">
            {/* <div className="slw_fast_icons">
              <div className="slidecontainer">
                <input
                  type="range"
                  min="1"
                  max="3"
                  className="speedSlider"
                  id="myRange"
                  value={animationSpeed}
                  onChange={(e) => changeAnimationSpeed(e)}
                />
              </div>
            </div> 
          </div>
        </div> */}
      </div>

      {/* Shape Selection Modal */}
      <ShapeSelectorModal
        showShapeModal={showShapeModal}
        setShowShapeModal={setShowShapeModal}
        handleShapeSelection={handleShapeSelection}
        selectedShape={selectedShape}
      />

      {/*<div className="inspire_me_image">
        <img
          className="normal_show"
          src="images/wiggle_doddle_main/inspireme_small.png"
        />
        <img
          style={{ display: "none" }}
          className="hover_show"
          src="images/wiggle_doddle_main/inspireme_small_over.gif"
        />
        <img
          style={{ display: "none" }}
          className="click_show"
          src="images/wiggle_doddle_main/inspireme_small_click.png"
        />
      </div>*/}
    </div>
  );
};

export default LeftTools;
