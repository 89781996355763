import React, { Component } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOutUser } from "../../redux/user/actions";
import Draggable from "react-draggable";
import { swal } from "react-redux-sweetalert2";
import { bindActionCreators } from "redux";
import WelcomeModal from "../../components/WelcomeModal";

class Dashboard extends Component {


  state = {
    showFlipbookGif: false,
    showAdvancedGif: false,
    showWiggleGif: false,
    showLessonsGif: false,
    showProjectVaultGif: false,
    guest: null
  };


  comingSoon = () => {
    this.props.swal({
      title: "",
      text: "Coming Soon..",
      type: "info",
      confirmAlert: () => false
    });
  };

  render() {
    const  hasSeenWelcomeModal = localStorage.getItem('hasSeenWelcomeModal');
    return (
      <div className="body_wrap">
        <div className="conta_iner">
        {!hasSeenWelcomeModal && <WelcomeModal />}
          <div className="animation_ish_box" style={{ height: "unset" }}>
            <div className="main_top_bann">
              <img style={{width: "100%"}} src="images/main_page/top/top_ani.gif" />
            </div>
            <div className="main_mid_bannr">
              <div className="main_mid_content">
                <div className="mid_logo_main">
                  <img src="images/main_page/midimages/homeTitle_wiggle.gif" />
                </div>
                <div className="mid_main_boxes">
                  <div className="mid_inner_boxes">
                    <Link to="/wiggledoodle">
                      <div
                        onMouseOver={() =>
                          this.setState({
                            showWiggleGif: true
                          })
                        }
                        onMouseOut={() =>
                          this.setState({
                            showWiggleGif: false
                          })
                        }
                      >
                        <div
                          className="inner_col"
                          style={
                            this.state.showWiggleGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                        <div
                          className="inner_col_hover"
                          style={
                            !this.state.showWiggleGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                      </div>
                    </Link>

                    <Link to="/flipbook">
                      <div
                        onMouseOver={() =>
                          this.setState({
                            showFlipbookGif: true
                          })
                        }
                        onMouseOut={() =>
                          this.setState({
                            showFlipbookGif: false
                          })
                        }
                      >
                        <div
                          className="inner_col_sec"
                          style={
                            this.state.showFlipbookGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                        <div
                          className="inner_col_sec_hover"
                          style={
                            !this.state.showFlipbookGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                      </div>
                    </Link>
                    <Link to="/advanced-doodle">
                      <div
                        onMouseOver={() =>
                          this.setState({
                            showAdvancedGif: true
                          })
                        }
                        // onClick={this.comingSoon}
                        onMouseOut={() =>
                          this.setState({
                            showAdvancedGif: false
                          })
                        }
                      >
                        <div
                          className="inner_col_third"
                          style={
                            this.state.showAdvancedGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                        <div
                          className="inner_col_third_hover"
                          style={
                            !this.state.showAdvancedGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                      </div>
                    </Link>
                    {/* <Link to="/sound">
                      <div
                        style={{
                          width: '100px',
                          height: '100px',
                          background:'yellow',
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: '200px',
                          color: "black",
                          fontWeight: '800'
                        }}
                        onClick={<>Sound-ish</>}
                      >Sound-ish
                        <div/>
                      </div>
                    </Link> */}
                  </div>
                  <div className="mid_inner_boxes mid_bottom_boxes">
                    <a
                      href="https://www.fablevisionlearning.com/animationish-lessons"
                      target="blank"
                    >
                      <div
                        onMouseOver={() =>
                          this.setState({
                            showLessonsGif: true
                          })
                        }
                        onMouseOut={() =>
                          this.setState({
                            showLessonsGif: false
                          })
                        }
                      >
                        <div
                          className="less_cols"
                          style={
                            this.state.showLessonsGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                        <div
                          className="less_cols_hover"
                          style={
                            !this.state.showLessonsGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                      </div>
                    </a>
                    <Link to="/project-vault">
                      <div
                        onMouseOver={() =>
                          this.setState({
                            showProjectVaultGif: true
                          })
                        }
                        onMouseOut={() =>
                          this.setState({
                            showProjectVaultGif: false
                          })
                        }
                      >
                        <div
                          className="less_cols_sec"
                          style={
                            this.state.showProjectVaultGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                        <div
                          className="less_cols_sec_hover"
                          style={
                            !this.state.showProjectVaultGif
                              ? { height: "0px", width: "0px" }
                              : {}
                          }
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="logoutDiv">
              <button
                className="logoutBtn"
                onClick={() => {
                  this.props.dispatch(logOutUser());
                }}
              >
                Logout
              </button>
            </div>

            <div className="main_bottom_bann">
              <img
                src="images/main_page/bootom_images/bottom_ani.gif"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  swal: bindActionCreators(swal.showAlert, dispatch)
});

export default connect(null, mapDispatchToProps)(Dashboard);
